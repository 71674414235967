import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";
import "../LandingPage/assets/css/main.css";
import "../LandingPage/assets/css/responsive.css";
import mobileLogo from "./assets/images/logo/mobile-logo.png";
import logo from "./assets/images/logo/logo.png";
import star1 from "./assets/images/icon/star-1.png";
import star2 from "./assets/images/icon/star-2.png";
import star3 from "./assets/images/icon/star-3.png";
import star4 from "./assets/images/icon/star-4.png";
import star5 from "./assets/images/icon/star-5.png";
import star6 from "./assets/images/icon/star-6.png";
import star7 from "./assets/images/icon/star-7.png";
import star8 from "./assets/images/icon/star-8.png";

// Hero Section Images
import heroImage from "./assets/images/banner/hero.png";
import overly12 from "./assets/images/shape/overly-12.svg";
import overly13 from "./assets/images/shape/overly-13.svg";

// Features Section Images
import overly7 from "./assets/images/shape/overly-7.svg";
import overly8 from "./assets/images/shape/overly-8.svg";

// Footer Section Images
import overly10 from "./assets/images/shape/overly-10.svg";
import secShape9 from "./assets/images/shape/sec-shape9.svg";

import piece from "./assets/images/icon/piece.png";
import time from "./assets/images/icon/time.png";
import timeTracking from "./assets/images/icon/time-tracking.png";
import pipline from "./assets/images/icon/pipline.png";
import { RxCross1 } from "react-icons/rx";

const LandingPage = () => {
  console.log("test");
  const [calendlyInitialized, setCalendlyInitialized] = useState(false);
  const scrollTopRef = useRef(null);
  const scrollProgressPatchRef = useRef(null);
  const offset = 50; // Define the offset for when to show the button
  const navigate = useNavigate();

  const initializeCalendly = () => {
    if (!calendlyInitialized) {
      const link = document.createElement("link");
      link.href = "https://assets.calendly.com/assets/external/widget.css";
      link.rel = "stylesheet";
      document.head.appendChild(link);

      const scriptCalendly = document.createElement("script");
      scriptCalendly.src =
        "https://assets.calendly.com/assets/external/widget.js";
      scriptCalendly.async = true;
      document.body.appendChild(scriptCalendly);

      scriptCalendly.onload = () => {
        window.Calendly.initBadgeWidget({
          url: "https://calendly.com/mrtunde/loyal-locker-demo",
          text: "Schedule a Demo",
          color: "#ff5833",
          textColor: "#ffffff",
          branding: false,
        });
      };

      setCalendlyInitialized(true);
    }
  };

  const removeCalendlyWidget = () => {
    const calendlyBadge = document.querySelector(".calendly-badge-widget");
    if (calendlyBadge) {
      calendlyBadge.remove();
    }
  };

  useEffect(() => {
    initializeCalendly(); // Initialize Calendly on mount

    return () => {
      removeCalendlyWidget(); // Clean up the Calendly widget on unmount
    };
  }, []);

  const handleLoginClick = () => {
    removeCalendlyWidget(); // Remove the widget before navigating
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY; // Use window.scrollY to get the scroll position
      const headerElement = document.getElementById("tj-header-sticky");

      if (scroll < 100) {
        headerElement.classList.remove("tj-header-sticky");
      } else {
        headerElement.classList.add("tj-header-sticky");
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Function to hide the loader
    const hideLoader = () => {
      const loadingElement = document.getElementById("loading");
      if (loadingElement) {
        loadingElement.style.display = "none";
      }
    };

    // Set a timeout to hide the loader after 500 milliseconds
    const timeoutId = setTimeout(hideLoader, 500);

    // Clean up the timeout and event listener when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleCloseLoader = (e) => {
    e.preventDefault();
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
      // Hide the loader with a fade-out effect after 500 milliseconds
      loadingElement.style.display = "none";
    }
  };

  useEffect(() => {
    const scrollProgressPatch = scrollProgressPatchRef.current;

    if (scrollProgressPatch) {
      const pathLength = scrollProgressPatch.getTotalLength();

      // Initial setup for path stroke
      scrollProgressPatch.style.transition =
        scrollProgressPatch.style.WebkitTransition = "none";
      scrollProgressPatch.style.strokeDasharray = `${pathLength} ${pathLength}`;
      scrollProgressPatch.style.strokeDashoffset = pathLength;
      scrollProgressPatch.getBoundingClientRect(); // Trigger a layout to apply the style changes
      scrollProgressPatch.style.transition =
        scrollProgressPatch.style.WebkitTransition =
          "stroke-dashoffset 10ms linear";

      const handleScroll = () => {
        const scroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
        const progress = pathLength - (scroll * pathLength) / height;
        scrollProgressPatch.style.strokeDashoffset = progress;

        const scrollElementPos = scroll;
        if (scrollElementPos >= offset) {
          scrollTopRef.current.parentElement.classList.add("progress-done");
        } else {
          scrollTopRef.current.parentElement.classList.remove("progress-done");
        }
      };

      // Attach the scroll event listener
      window.addEventListener("scroll", handleScroll);

      return () => {
        // Cleanup the scroll event listener
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []); // Empty dependency array to run only once after initial render

  useEffect(() => {
    const handleCanvasExpand = (e) => {
      e.preventDefault();
      document.body.classList.toggle("canvas_expanded");
    };

    const canvaExpander = document.querySelectorAll(
      ".canva_expander, #canva_close, #tj-overlay-bg2"
    );

    if (canvaExpander.length) {
      canvaExpander.forEach((element) => {
        element.addEventListener("click", handleCanvasExpand);
      });
    }

    // Cleanup event listeners on component unmount
    return () => {
      canvaExpander.forEach((element) => {
        element.removeEventListener("click", handleCanvasExpand);
      });
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landing-page">
      <div id="tj-overlay-bg2" className="tj-overlay-canvas"></div>

      <div id="loading">
        <div id="loading-center">
          <div id="loading-center-absolute">
            <div className="object" id="object_four"></div>
            <div className="object-1" id="object_three"></div>
            <div className="object-2" id="object_two"></div>
            <div className="object-3" id="object_one"></div>
          </div>
          <button
            className="closeLoader tj-primary-black-btn"
            onClick={handleCloseLoader}
          >
            <span>Cancel Preloader</span>
          </button>
        </div>
      </div>

      <div className="tj-offcanvas-area">
        <div className="tj-offcanvas-header d-flex align-items-center justify-content-between">
          <div className="logo-area text-center">
            <a href="/">
              <img src={mobileLogo} alt="Loyal Locker Logo" />
            </a>
          </div>
          <div className="offcanvas-icon">
            <a id="canva_close" href="#">
              <RxCross1 style={{ fontSize: "32px", color: "white" }} />
            </a>
          </div>
        </div>
        <nav
          className="right_menu_togle mobile-navbar-menu d-lg-none"
          id="mobile-navbar-menu"
        >
          <div
            style={{
              marginTop: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={handleLoginClick}
              style={{
                color: "#FFFFFF",
                fontSize: "26px",
                fontWeight: 700,
                background: "linear-gradient(90deg, #006C65, #F15A29)", // matching the gradient colors
                border: "none",
                padding: "15px 30px",
                borderRadius: "8px", // adding rounded corners
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // adding a subtle shadow for depth
                cursor: "pointer",
                transition: "background 0.3s ease", // smooth hover transition
              }}
              onMouseEnter={(e) => (e.target.style.background = "#F15A29")} // hover effect
              onMouseLeave={(e) =>
                (e.target.style.background =
                  "linear-gradient(90deg, #006C65, #F15A29)")
              } // reset hover
            >
              Login
            </button>
          </div>
        </nav>
      </div>

      <header className="tj-header-area" id="tj-header-sticky">
        <div className="container">
          <div className="row">
            <div className="header-content-two">
              <div className="logo-area">
                <a href="/">
                  <img src={logo} alt="Loyal Locker Logo" />
                </a>
              </div>
              <div
                className="tj-main-menu d-lg-block d-none text-center"
                id="main-menu"
              >
                <ul className="main-menu"></ul>
              </div>
              <div className="header-button-box d-lg-block d-none">
                {/* <div className="tj-login-button header-button">
                  <a
                    className="tj-black-btn"
                    href="https://loyallocker.com/login/"
                  >
                    {" "}
                    Login{" "}
                  </a>
                </div> */}
                <div className="tj-login-button header-button">
                  <button className="tj-black-btn" onClick={handleLoginClick}>
                    Login
                  </button>
                </div>
                <div className="tj-singup-button header-button">
                  <a
                    className="tj-primary-black-btn"
                    href="https://calendly.com/mrtunde/loyal-locker-demo"
                  >
                    {" "}
                    Schedule a Demo
                  </a>
                </div>
              </div>
              <div className="tj-canva-icon d-lg-none">
                <a
                  className="canva_expander nav-menu-link menu-button"
                  href="#"
                >
                  <span className="dot1"></span>
                  <span className="dot2"></span>
                  <span className="dot3"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="tj-hero-section-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-content-two text-center">
                <div className="tj-sec-heading-two">
                  <h1 className="title">
                    Unlock Growth with Web3 Loyalty Rewards
                  </h1>
                  <p className="desc">
                    Loyal Locker brings the power of Web3 to your business,
                    offering an intuitive platform to reward customer loyalty
                    effortlessly. Elevate engagement, boost retention, and watch
                    your business thrive with decentralized rewards..
                  </p>
                </div>
              </div>
              <div className="hero-middle-area">
                <div className="hero-input-form">
                  <div className="tj-hero-button">
                    <a href="https://calendly.com/mrtunde/loyal-locker-demo">
                      {" "}
                      <button
                        className="tj-primary-black-btn"
                        type="submit"
                        value="submit"
                      >
                        Schedule a Demo
                      </button>{" "}
                    </a>
                  </div>
                </div>
                <div className="header-list text-center">
                  <ul className="list-gap">
                    <li>
                      <i className="flaticon-checkmark"></i> Free Trial
                      Available
                    </li>
                  </ul>
                </div>
              </div>
              <div className="hero-image shake-y text-center">
                <img src={heroImage} alt="Image" />
              </div>
            </div>
          </div>
        </div>
        <div className="hero-overly-image pulse">
          <img src={overly12} alt="overly" />
        </div>
        <div className="hero-overly-image1">
          <img src={overly13} alt="overly" />
        </div>
        <div className="tj-star-box">
          <img className="star-1" src={star1} alt="Icons" />
          <img className="star-2" src={star2} alt="Icons" />
          <img className="star-3" src={star3} alt="Icons" />
          <img className="star-4" src={star2} alt="Icons" />
          <img className="star-5" src={star3} alt="Icons" />
          <img className="star-6" src={star4} alt="Icons" />
          <img className="star-7" src={star3} alt="Icons" />
          <img className="star-8" src={star4} alt="Icons" />
        </div>
      </section>

      <section className="tj-feature-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tj-sec-heading-two text-center">
                {/* <span className="sub-title"> Features</span> */}
                <h2 className="title">Enjoy the wide range of features</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tj-feature-item">
                <div className="feature-icon">
                  <img
                    className="star-8"
                    src={piece}
                    alt="Icons"
                    height="30px"
                  />
                </div>
                <div className="feature-content">
                  <h5 className="title">Decentralized Loyalty Rewards</h5>
                  <p className="desc">
                    Offer your customers rewards that are secure, transparent,
                    and easily transferable across multiple platforms..
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tj-feature-item">
                <div className="feature-icon">
                  <img
                    className="star-8"
                    src={time}
                    alt="Icons"
                    height="40px"
                  />
                </div>
                <div className="feature-content">
                  <h5 className="title">Customizable Loyalty Programs</h5>
                  <p className="desc">
                    Tailor loyalty programs to fit your unique needs, giving you
                    full control over rewards, tiers, and customer interactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tj-feature-item">
                <div className="feature-icon">
                  <img
                    className="star-8"
                    src={timeTracking}
                    alt="Icons"
                    height="40px"
                  />
                </div>
                <div className="feature-content">
                  <h5 className="title">Seamless Blockchain Integration</h5>
                  <p className="desc">
                    Leverage the power of blockchain for secure transactions and
                    data management, ensuring integrity & transparency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tj-feature-item">
                <div className="feature-icon">
                  <img
                    className="star-8"
                    src={pipline}
                    alt="Icons"
                    height="40px"
                  />
                </div>
                <div className="feature-content">
                  <h5 className="title">Multi-Channel Campaign Support</h5>
                  <p className="desc">
                    Reach your customers where they are by creating campaigns
                    via email and SMS, all managed from a single platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-overly">
          <img src={overly7} alt="Shape" />
        </div>
        <div className="feature-overly-1">
          <img src={overly8} alt="Shape" />
        </div>
        <div className="tj-star-group">
          <img className="icon-1" src={star5} alt="Icon" />
          <img className="icon-2" src={star6} alt="Icon" />
          <img className="icon-3" src={star7} alt="Icon" />
          <img className="icon-4" src={star8} alt="Icon" />
        </div>
        <div className="tj-star-group tj-star-group-1">
          <img className="icon-1" src={star5} alt="Icon" />
          <img className="icon-2" src={star6} alt="Icon" />
          <img className="icon-3" src={star7} alt="Icon" />
          <img className="icon-4" src={star8} alt="Icon" />
        </div>
      </section>

      <footer className="tj-footer-area footer-v2">
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="desc text-center">
                  Copyright © 2024{" "}
                  <a href="#" target="_blank">
                    {" "}
                    Loyal Locker.{" "}
                  </a>{" "}
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_shape">
          <img src={overly10} alt="Shape" />
        </div>
        <div className="footer_shape1">
          <img src={secShape9} alt="Shape" />
        </div>
        <div className="footer-star-group">
          <img className="star-1" src={star1} alt="Icon" />
          <img className="star-2" src={star2} alt="Icon" />
          <img className="star-3" src={star3} alt="Icon" />
        </div>
      </footer>

      <div className="saasify-scroll-top">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            ref={scrollProgressPatchRef}
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919px, 307.919px",
              strokeDashoffset: "307.919px", // Initial value to match path length
            }}
          ></path>
        </svg>
        <div
          className="saasify-scroll-top-icon"
          ref={scrollTopRef}
          onClick={handleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            data-icon="mdi:arrow-up"
            className="iconify iconify--mdi"
          >
            <path
              fill="currentColor"
              d="M13 20h-2V8l-5.5 5.5l-1.42-1.42L12 4.16l7.92 7.92l-1.42 1.42L13 8v12Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
